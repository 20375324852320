import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';

class Breadcrumb extends Component {

	/**
	 * Método para construir un JSON con las secciones de la miga de pan
	 * @param {Function} t : Función de traducciones
	 */
	buildBookingCheckoutBreadcrumbSteps(t) {
		return [
		{
			"icon": <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 19 19" fill="currentColor">
					<g transform="translate(0 -2)translate(0 1)">
						<g transform="translate(0 0.9802)">
						<path d="M6.5 17L7.3 17.7 17.5 7 16.7 6.3C15.5 7.2 13.8 7.2 12.7 6.1 12.1 5.5 11.7 4.7 11.7 3.9 11.7 3.2 11.9 2.6 12.2 2L11.5 1.3 1.3 12 2 12.7C3.3 11.8 5 11.9 6.1 12.9 6.7 13.5 7.1 14.3 7.1 15.2 7.1 15.8 6.9 16.5 6.5 17ZM7.3 19C7.1 19 7 18.9 6.9 18.8L5.4 17.4C5.2 17.2 5.2 16.9 5.4 16.7 5.8 16.3 6 15.7 6 15.2 6 14.6 5.7 14.1 5.3 13.7 4.5 13 3.2 13 2.4 13.8 2.2 14 1.9 14 1.6 13.8L0.2 12.4C0.1 12.3 0 12.2 0 12 0 11.9 0.1 11.7 0.2 11.6L11.1 0.2C11.3 0 11.7 0 11.9 0.2L13.4 1.6C13.5 1.7 13.5 1.8 13.5 2 13.5 2.1 13.5 2.3 13.4 2.4 13 2.8 12.8 3.3 12.8 3.8 12.8 4.4 13 4.9 13.4 5.3 14.3 6.1 15.6 6 16.4 5.2 16.5 5.1 16.6 5.1 16.7 5 16.9 5 17 5.1 17.1 5.2L18.6 6.6C18.8 6.8 18.8 7.2 18.6 7.4L7.7 18.8C7.6 18.9 7.4 19 7.3 19L7.3 19Z"/>
						</g>
						<path d="M8.5 6.3C8.4 6.3 8.3 6.3 8.2 6.2L7.7 5.7C7.6 5.6 7.6 5.3 7.7 5.2 7.9 5 8.1 5 8.3 5.1L8.8 5.6C8.9 5.8 8.9 6 8.8 6.2 8.7 6.3 8.6 6.3 8.5 6.3"/>
						<path d="M10.6 8.3C10.5 8.3 10.4 8.3 10.3 8.2L9.3 7.2C9.1 7 9.1 6.8 9.2 6.6 9.4 6.5 9.6 6.5 9.8 6.6L10.8 7.6C11 7.8 11 8 10.9 8.2 10.8 8.3 10.7 8.3 10.6 8.3M12.7 10.3C12.6 10.3 12.5 10.3 12.4 10.2L11.3 9.2C11.2 9 11.2 8.8 11.3 8.6 11.5 8.5 11.7 8.5 11.9 8.6L12.9 9.6C13.1 9.8 13.1 10 12.9 10.2 12.9 10.3 12.8 10.3 12.7 10.3"/>
						<path d="M14.2 11.8C14.1 11.8 14 11.7 13.9 11.7L13.4 11.2C13.3 11 13.3 10.8 13.4 10.6 13.6 10.5 13.8 10.4 14 10.6L14.5 11.1C14.6 11.2 14.6 11.5 14.5 11.6 14.4 11.7 14.3 11.8 14.2 11.8"/>
					</g>
					</svg>,
			"title": t("Precio y disponibilidad"),
			"name": "precio-y-disponibilidad"
		},
		{ "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12"><g fill="none"><g fill="currentcolor"><path d="M3.6 3.8C3.7 3.9 3.7 3.9 3.7 4 4 5 4.3 6 4.5 7.1 4.6 7.2 4.6 7.2 4.7 7.2 5 7.2 5.4 7.2 5.7 7.2 5.8 7.2 5.8 7.2 5.8 7.1 5.7 6.6 5.6 6.2 5.5 5.7 5.3 5.1 5.2 4.5 5.1 4 5.1 3.9 5 3.8 4.9 3.8 4.8 3.8 4.6 3.8 4.5 3.8L3.6 3.8ZM9.8 7.2C10 7.2 10.2 7.2 10.3 7.2 10.4 7.2 10.5 7.2 10.5 7.1 10.5 6.9 10.6 6.6 10.7 6.4 10.9 5.6 11.1 4.8 11.4 4 11.4 3.8 11.4 3.8 11.2 3.8 10.9 3.8 10.5 3.8 10.1 3.8 10 3.8 10 3.8 9.9 3.9 9.9 4.2 9.8 4.5 9.7 4.8 9.6 5.6 9.4 6.3 9.2 7 9.2 7.2 9.2 7.2 9.4 7.2 9.5 7.2 9.7 7.2 9.8 7.2ZM6.5 3.8C6.5 3.9 6.5 3.9 6.5 4 6.7 5 6.8 6 7 7.1 7 7.2 7.1 7.2 7.2 7.2 7.4 7.2 7.6 7.2 7.8 7.2 7.9 7.2 8 7.2 8 7.1 8.1 6.2 8.3 5.3 8.5 4.4 8.5 4.2 8.5 4 8.6 3.8L6.5 3.8ZM0 0.5C0.1 0.2 0.3 0 0.6 0 0.7 0 0.8 0 0.9 0 1.3 0.2 1.8 0.3 2.3 0.4 2.5 0.5 2.7 0.7 2.8 0.9 2.9 1.3 3 1.8 3.1 2.2 3.2 2.3 3.2 2.3 3.3 2.3 6.6 2.3 9.9 2.3 13.1 2.3 13.4 2.3 13.6 2.4 13.8 2.6 14 2.8 14 3 13.9 3.3 13.8 3.6 13.5 3.7 13.2 3.7 13.1 3.7 13 3.7 12.9 3.7 12.8 3.7 12.8 3.8 12.8 3.9 12.5 4.9 12.2 6 11.9 7 11.7 7.4 11.6 7.8 11.5 8.1 11.4 8.5 11.2 8.7 10.8 8.7L5.2 8.7 5 8.7C5 8.9 5.1 9.1 5.1 9.3 5.5 9.3 5.8 9.3 6.1 9.4 6.9 9.4 7.6 9.4 8.3 9.5 8.9 9.5 9.5 9.6 10 9.6 10.4 9.6 10.7 9.7 11.1 9.7 11.5 9.8 11.9 10.3 11.9 10.7 11.8 11.2 11.5 11.6 11 11.6 10.5 11.7 9.9 11.3 9.9 10.7L5.3 10.7C5.3 11 5.2 11.2 4.9 11.4 4.5 11.8 3.9 11.7 3.5 11.2 3.2 10.8 3.3 10.2 3.8 9.9 3.9 9.8 3.9 9.8 3.8 9.7 3.1 7 2.4 4.4 1.6 1.7 1.6 1.6 1.6 1.6 1.5 1.6 1.2 1.5 0.9 1.4 0.6 1.3 0.3 1.3 0.1 1.1 0 0.8L0 0.5Z"/></g></g></svg>,
			"title": t("Compra"),
			"name": "compra"
		},
		{
			"icon":	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11"><g fill="none"><g transform="translate(1 0)"><path d="M12.2 10.4L1.8 10.4C1.1 10.4 0.5 9.8 0.5 9L0.5 1.8C0.5 1.1 1.1 0.5 1.8 0.5L12.2 0.5C13 0.5 13.6 1.1 13.6 1.8L13.6 9C13.6 9.8 13 10.4 12.2 10.4Z" stroke="currentcolor"/><polygon points="0.6 4.9 13.7 4.9 13.7 3.1 0.6 3.1" fill="currentcolor"/></g></g></svg>
		,
			"title": t("Pago"),
			"name": "pago"
		},
		{
			"icon":	<svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16"><g style={{fill:'none', strokeLinejoin:'round'}}><path d="M2 7.7L7.7 13.7C7.7 13.7 11.3 6 17 2" style={{strokeWidth:3,stroke:'currentcolor'}}/></g></svg>,
			"title": t("Confirmación"),
			"name": "confirmacion"
		},
		];
	}

  	render() {
		const { t } = this.props;
		let steps = this.buildBookingCheckoutBreadcrumbSteps(t);
		const arrowRight = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9"><g fill="none"><path d="M0 4.1L10.5 4.1" stroke="#4B4B4D"/><polygon points="7.2 0 6.5 0.7 10.1 4.1 6.5 7.4 7.2 8.2 11.5 4.1" fill="#4B4B4D"/></g></svg>;
		return	<div className={`breadcrumb d-none d-lg-flex`}>
					{steps.map((step, i) => {
						return 	<span key={`step-` + i}>
									{i !== 0 && 
										<span className={`mx-1`}>
											{arrowRight}
										</span>
									}
									<span key={`step-` + i} className={this.props.step === step.name ? 'active' : ''}>
										{step.icon}
										&nbsp;
										{step.title}
									</span>
								</span>
					})}
				</div>;
	  }
}

export default withTranslation('Breadcrumb')(Breadcrumb);