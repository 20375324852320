import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { withTranslation, I18nextContext } from "gatsby-plugin-react-i18next"
import queryString from "query-string"
import UIkit from "uikit"
import VolcanoUniversalPayWidget from "@volcanoteide/vte-components/dist/UniversalPayWidget/VolcanoUniversalPayWidget"


class OrderPayment extends Component {
  static contextType = I18nextContext


  componentDidMount() {
    if (typeof window !== `undefined`) {
      UIkit.container = ".uk-scope"
    }
  }
  
  handleResult(data) {
    console.log(data)
  }

  render() {
    const { location } = this.props
    const params = queryString.parse(location.search)

    return (
      <div className={`articulo pb-5`}>
        <StaticQuery
          query={graphql`
            query OrderPaymenttVolcanoTeideAPIConfig {
              site {
                siteMetadata {
                  volcanoTeideAPIConfig {
                    protocol
                    host
                    port
                    strictSSL
                    timeout
                    site_key
                  }
                }
              }
            }
          `}
          render={data => (
            <VolcanoUniversalPayWidget
              scriptUrl={params.scriptUrl}
              baseUrl={params.url}
              token={params.token}
              merchantId={params.merchantId}
              onResult={this.handleResult}
            />
          )}
        />
      </div>
    )
  }
}

export default withTranslation("OrderPayment")(OrderPayment)
